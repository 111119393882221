import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { AppContextProvider } from './components/AppContextProvider';
import { getConfig } from './lib/api';
import { initMsAuthInstance } from './lib/auth';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

(async () => {
  const config = await getConfig();
  const msalInstance = await initMsAuthInstance(config.app.msAuth.clientId);
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <AppContextProvider config={{ ...config, msalInstance }}>
        <App />
      </AppContextProvider>
    </React.StrictMode>
  );
})();
