import { useContext } from 'react';
import { MsalProvider } from '@azure/msal-react';

import CreateCasePage from './pages/CreateCase/index.js';
import { Authenticated, Header } from './components';

import './App.css';

import { AppContext } from './components/AppContextProvider.js';

function App() {
  const { app, msalInstance } = useContext(AppContext);

  return (
    <MsalProvider instance={msalInstance}>
      <div
        className="app"
        style={{
          '--theme-error-color': app.theme.errorColor,
          '--theme-label-color': app.theme.labelColor,
          '--theme-primary-color': app.theme.primaryColor,
          '--theme-title-color': app.theme.titleColor,
        }}
      >
        <Header />
        <Authenticated>
          <CreateCasePage />
        </Authenticated>
        <div className="app-background--bottom fixed-bottom" />
      </div>
    </MsalProvider>
  );
}

export default App;
