import fieldDefs from '../config/field-definitions';

export const transformFormsConfig = ({ fields, forms }) => {
  return forms.map(form => ({
    ...form,
    fields: {
      ...form.fields,
      visible: form.fields.visible.map(field =>
        typeof field === 'object'
          ? {
              ...fieldDefs[field.name],
              ...fields[field.name],
              ...field,
            }
          : {
              name: field,
              ...fieldDefs[field],
              ...fields[field],
            }
      ),
    },
  }));
};
