import { useContext } from 'react';

import { AppContext } from '../../../components/AppContextProvider';
import { transformContactsFields, transformFormsConfig } from '../helpers';
import { useContacts } from './use-contacts';

export const useFormDefs = () => {
  const { error, contacts } = useContacts();
  const { fields, forms } = useContext(AppContext);
  return {
    formDefs: transformContactsFields({
      contacts,
      config: transformFormsConfig({ fields, forms }),
    }),
    error,
  };
};
