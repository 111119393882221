import { useMsal } from '@azure/msal-react';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../../components/AppContextProvider';
import { getContactsByEmail } from '../../../lib/api';

export const useContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [error, setError] = useState();
  const { accounts } = useMsal();
  const {
    app: { defaultContact },
  } = useContext(AppContext);
  const email = accounts?.[0]?.username || defaultContact;

  useEffect(() => {
    (async () => {
      try {
        const contacts = await getContactsByEmail(email);
        setContacts(contacts);
      } catch (e) {
        setError(e);
      }
    })();
  }, [email]);

  return {
    contacts,
    error,
  };
};
